<template>
<div class="slenDblue text-center white--text pt-6">
    <base-heading>
    {{title}}
    </base-heading>
    <v-carousel
    hide-delimiters
    :show-arrows="false"
    cycle
    :height="carouselHeight"
    >
        <v-carousel-item
        v-for="(statement, i) in statements"
            :key="i"
        >
            <v-sheet
            color="#155b66"
            height="100%"
            >
                <v-row
                class="fill-height pa-0 ma-0"
                justify="center"
                >
                  <v-col md="8">
                    <div class="white--text mb-5" id="words">
                    {{ statement.words }}
                    </div>
                    <div class="white--text mt-10" id="who">
                    {{ statement.who }}
                    </div>
                   </v-col> 
                </v-row>
            </v-sheet>
        </v-carousel-item>
    </v-carousel>
</div>    
</template>

<script>
    export default{
        name: 'CustomerStatements',

        computed: {
            carouselHeight(){
                let val=0
                switch (this.$vuetify.breakpoint.name)
        {
          case 'xs':
          val=330
          break;
          case 'sm':
          val=315
          break;
          case 'md':
          val=260
          break;
          case 'lg':
          val=220
          break;
          case 'xl':
          val=220
          break;
        }
        return val
      },
            title(){  
                    return this.$store.getters['customerStatementsCMS/getTitle']
                    },
            statements(){
                    return this.$store.getters['customerStatementsCMS/getCus_statements']
                    }
        },
        created(){
            this.$store.dispatch('customerStatementsCMS/loadContent')
        },

    }
</script>

<style scoped>
span{
    font-size: 2em;
    font-weight: bold;
    display: inline-block;
    font-family: 'Open Sans';
    margin-top: 1em;
}
#words{
    font-style: italic;
    font-family: 'Open Sans';
    font-size: 1.2rem;
}
#who{
    font-weight: bold;
    font-family: 'Open Sans';
    font-size: 1em;
}
</style>
